import React from "react"
import { Link } from "gatsby"
import socialLinks from "../constants/social_links"
import { StaticImage } from "gatsby-plugin-image"

const NewsList = () => {
  return (
    <>
      <div className="section-newslist bg-gray">
        <div className="newslist-center">
          <ul className="news-list">
            <li className="item">
              <div className="news-item">
                <p className="date">2023-10-11</p>
                <p className="category">
                  <span>News</span>
                </p>
                <p className="title">
                  Our exhibition "AR Ricci Map", which uses AR to enhance Ricci
                  maps, has started at the "China in Maps" exhibition in the
                  HKUST library.
                </p>
              </div>
            </li>
            <li className="item">
              <div className="news-item">
                <p className="date">2023-08-01</p>
                <p className="category">
                  <span>News</span>
                </p>
                <p className="title">
                  Our work "Landslide Visualization Situated on Tangible Terrain
                  Models" has been accepted for the poster session at IEEE VIS
                  '23.
                </p>
              </div>
            </li>
            <li className="item">
              <div className="news-item">
                <p className="date">2023-07-15</p>
                <p className="category">
                  <span>News</span>
                </p>
                <p className="title">
                  "Echoes in the Gallery: A Collaborative Immersive Analytics
                  System for Analyzing Audience Reactions in Virtual Reality
                  Exhibitions" has been accepted as a paper for the MERCADO
                  Workshop at IEEE VIS '23.
                </p>
              </div>
            </li>

            <li className="item">
              <div className="news-item">
                <p className="date">2022-09-01</p>
                <p className="category">
                  <span>News</span>
                </p>
                <p className="title">
                  I have enrolled in the Ph.D. program at the Department of
                  Computer Science and Engineering at the Hong Kong University
                  of Science and Technology.
                </p>
              </div>
            </li>

            <li className="item">
              <div className="news-item">
                <p className="date">2022-08-22</p>
                <p className="category">
                  <span>News</span>
                </p>
                <p className="title">
                  My new course, "Introduction to Data Visualization with
                  Python", has been released on Schoo, a leading Japanese online
                  course platform.
                </p>
              </div>
            </li>

            <li className="item">
              <div className="news-item">
                <p className="date">2022-04-22</p>
                <p className="category">
                  <span>News</span>
                </p>
                <p className="title">
                  My new course, "Learning Data Visualization through Practical
                  Problems", has been released on Schoo, a leading Japanese
                  online course platform.
                </p>
              </div>
            </li>

            <li className="item">
              <div className="news-item">
                <p className="date">2022-01-22</p>
                <p className="category">
                  <span>News</span>
                </p>
                <p className="title">
                  Our paper titled "Forecasting The Movement Of Internally
                  Displaced People By Artificial Intelligence", co-written in
                  collaboration with United Nations , has been accepted as a
                  Book Chapter for Bright Network.
                </p>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </>
  )
}

export default NewsList
