import React from "react"
import { graphql } from "gatsby"
import socialLinks from "../constants/social_links"
import { getLowResolutionImageURL, StaticImage } from "gatsby-plugin-image"
import styled from "styled-components"
import ThemeContext from "../context/ThemeContext"
import NewsList from "../components/NewsList"

import SEO from "../components/Seo"

const IndexPage = ({ data }) => {
  const getProfile = language => {
    let nameEn = "Kentaro Takahira"
    let nameJp = "高比良健太郎"
    let vislablink = <a href={"http://vis.cse.ust.hk"}>VisLab</a>
    let proflink = <a href={"http://huamin.org"}>Huamin Qu</a>

    let selfEn = selfIntroductionEn.selfIntroductionEn

    let selfJp = selfIntroduction.selfIntroduction

    return {
      name: language == "JP" ? nameJp : nameEn,
      selfIntroduction: language == "JP" ? selfJp : selfEn,
    }
  }

  const getPosition = language => {
    const p1En = "Lead Data Scientist, Veldt.inc Tokyo | Mar. 2021 ~ Oct. 2021"
    const p1Jp =
      "データサイエンティスト&プロダクトリーダー 株式会社ヴェルト 東京 | 2021年3月 ~ 2021年10月"
    const p1DEn =
      "Engaged in the construction and implementation of causal inference and prediction algorithms for edge devices."
    const p1DJp =
      "エッジ端末における因果推論および予測アルゴリズムの構築および実装に従事。"
    const p2En =
      "Data Scientist Analyst, Accenture Tokyo | August 2019 ~ March 2021"
    const p2Jp =
      "データサイエンティストアナリスト アクセンチュア ストラテジーアンドコンサルティング部 東京 | 2019年8月 ~ 2021年3月"
    const p2DEn =
      "Worked on building inventory optimization models and mathematical simulations, and machine learning systems for OMO marketing."
    const p2DJp =
      "在庫最適化モデルの構築および数理シミュレーション、OMOマーケティングのための機械学習システムの構築に従事。"

    const p3En = "Data Analyst Intern, Actavo Leeds | August 2017 ~ March 2018"
    const p3Jp =
      "データアナリストインターン Actavo.ltd リーズ 英国 | 2017年8月 ~ 2018年3月"
    const p3DEn =
      "Worked on building a data flow and implementing a BI tool for visualizing the progress of a manufacturing process."
    const p3DJp =
      "製造工程の進捗状況可視化のためのデータフローの構築およびBIツールの実装に従事。"

    const p4En =
      "Research Intern, NTT Basic Laboratory Tokyo | August 2016 ~ September 2016"
    const p4Jp = "リサーチインターン NTT基礎研究所 東京 | 2016年8月 ~ 2016年9月"
    const p4DEn =
      "Worked on data analysis for a smart city project in Mitaka City."
    const p4DJp =
      "三鷹市におけるスマートシティプロジェクトにおけるデータ解析業務に従事。"

    if (language == "JP") {
      return [
        { t: p1Jp, d: p1DJp },
        { t: p2Jp, d: p2DJp },
        { t: p3Jp, d: p3DJp },
        { t: p4Jp, d: p4DJp },
      ]
    } else {
      return [
        { t: p1En, d: p1DEn },
        { t: p2En, d: p2DEn },
        { t: p3En, d: p3DEn },
        { t: p4En, d: p4DEn },
      ]
    }
  }

  const getEducation = language => {
    const e1En = "Master's Degree, Informatics, Kyoto University"
    const e1Jp = "修士（情報学）京都大学情報学研究科"
    const e1DEn =
      "Immersed in research on the application of artificial intelligence technology to urban transportation problems at Ishida-Matsubara laboratory. The research results have been presented and published in a prominent international conference, AAAI."
    const e1DJp =
      "エッジ端末における因果推論および予測アルゴリズムの構築および実装に従事。"
    const e2En = "Bachelor's Degree, Economics, Kyoto University"
    const e2Jp = "学士（経済学）京都大学経済学部"
    const e2DEn =
      "Engaged in research on econometrics and mathematical statistics at Yoshihiko Nishiyama’s laboratory."
    const e2DJp =
      "西山慶彦研究室において、計量経済学および数理統計学の研究に従事。"

    if (language == "JP") {
      return [
        { t: e1Jp, d: e1DJp },
        { t: e2Jp, d: e2DJp },
      ]
    } else {
      return [
        { t: e1En, d: e1DEn },
        { t: e2En, d: e2DEn },
      ]
    }
  }

  const getSkills = language => {
    const s1En = "Machine Learning / Statistical Modeling"
    const s1Jp = "機械学習および数理モデリング"
    const s1DEn =
      "Construction of supply-demand prediction models and visitor judgment models."
    const s1DJp = "需給予測モデルや来訪判定モデルの構築など。"

    const s2En = "Data Visualization"
    const s2Jp = "データ可視化"
    const s2DEn =
      "Vizualization of Data with D3 and various web-related technologies."
    const s2DJp = "D3や各種WEB技術を用いたデータ可視化。"

    const s3En = "AR/VR/XR Development"
    const s3Jp = "AR/VR/XR 開発"
    const s3DEn = "Design AR/VR/XR system by using WebXR or Unity."
    const s3DJp = "UnityおよびWebXR技術を用いたAR/VR/XRシステム開発"

    // const s4En = "Geo Data Analysis"
    // const s4Jp = "空間データ解析"

    // const s4DEn =
    //   "Analysis of GPS data obtained from people/vehicles, satellite data analysis, etc."
    // const s4DJp = "人/車両等から得られるGPSデータの解析や衛星データ解析など。"

    const s5En = "Agile Development / DevOps"
    const s5Jp = "アジャイル開発"
    const s5DEn =
      "Agile development Project Manager, CI/CD, Test Driven Development, etc."
    const s5DJp =
      "アジャイル開発のプロジェクトマネージャおよびCI/CD、テスト駆動開発など。"

    const s6En = "Creative Coding"
    const s6Jp = "クリエイティブコーディング"
    const s6DEn = "Art creation using p5js, Photoshop and etc."
    const s6DJp = "p5jsやPhotoshopなどを用いたアート制作。"

    if (language == "JP") {
      return [
        { t: s1Jp, d: s1DJp },
        { t: s2Jp, d: s2DJp },
        { t: s3Jp, d: s3DJp },
        // { t: s4Jp, d: s4DJp },
        { t: s5Jp, d: s5DJp },
        { t: s6Jp, d: s6DJp },
      ]
    } else {
      return [
        { t: s1En, d: s1DEn },
        { t: s2En, d: s2DEn },
        { t: s3En, d: s3DEn },
        // { t: s4En, d: s4DEn },
        { t: s5En, d: s5DEn },
        { t: s6En, d: s6DEn },
      ]
    }
  }

  const {
    contentfulProfile: { myphoto, selfIntroduction, selfIntroductionEn },
  } = data

  // const [language, setLanguage] = React.useState("JP")

  const profileAll = {
    EN: {
      profile: getProfile("EN"),
      position: getPosition("EN"),
      education: getEducation("EN"),
      skills: getSkills("EN"),
    },
    JP: {
      profile: getProfile("JP"),
      position: getPosition("JP"),
      education: getEducation("JP"),
      skills: getSkills("JP"),
    },
  }

  return (
    <>
      <SEO title="Kentaro Takahira" />
      <ThemeContext.Consumer>
        {theme => (
          <main className="page">
            <section className="about-page section projects">
              <div className="section-center about-center">
                <div className="hero-container">
                  <article className="about-text">
                    <MyName className="myName">
                      {profileAll[theme.language].profile.name}
                    </MyName>
                    <P className="aboutP">
                      {profileAll[theme.language].profile.selfIntroduction}
                    </P>

                    <div className="social-links">
                      {socialLinks.map(link => {
                        return (
                          <a
                            href={link.url}
                            key={link.id}
                            className="social-link"
                          >
                            {link.icon}
                          </a>
                        )
                      })}
                    </div>
                  </article>
                  <div class="profileImg">
                    <StaticImage
                      src="../assets/images/myProfileArt.png"
                      alt="contact"
                      className="profileImage"
                      placeholder="tracedSVG"
                      layout="constrained"
                    ></StaticImage>
                    <p style={{ textAlign: "right" }}>
                      by{" "}
                      <a href="https://github.com/vijishmadhavan/ArtLine">
                        ArtLine
                      </a>
                    </p>
                  </div>
                </div>
              </div>
              <NewsList />
            </section>
          </main>
        )}
      </ThemeContext.Consumer>
    </>
  )
}

export default IndexPage

export const query = graphql`
  {
    contentfulProfile {
      myphoto {
        gatsbyImageData(layout: CONSTRAINED, placeholder: TRACED_SVG)
      }
      selfIntroduction {
        selfIntroduction
      }
      selfIntroductionEn {
        selfIntroductionEn
      }
      id
    }
  }
`

const H4 = styled.h4`
  &:before {
    content: "▶ ";
    font-size: small;
    vertical-align: middle;
  }
`
const H3 = styled.h3`
  position: relative;
  &:before {
    content: "";
    position: absolute;
    bottom: -1vh;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: var(--clr-primary-1);
  }
`

const MyName = styled.h2`
  font-family: "Shippori Mincho", sans-serif;
  position: relative;
  &:before {
    content: "";
    position: absolute;
    bottom: -1vh;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: var(--clr-primary-1);
  }
`

const P = styled.p`
  font-family: "Shippori Mincho", sans-serif;
`
